import React from 'react';

const UsageInstructions = ({ receiverEndpoint, scrollToLogs }) => {
  return (
    <div className="usage-instructions">
      <h2>Receiver Endpoint</h2>
      <p>
        Use the following endpoint to send your log data:
      </p>
      <pre className="endpoint-url">{receiverEndpoint}</pre>
      <h3>Directions</h3>
      <p>
        To use the receiver endpoint, make a POST request with your log data in the body. Here are some examples in different languages:
      </p>
      <h4>JavaScript (Fetch API)</h4>
      <pre>
        {`fetch('${receiverEndpoint}', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({ log: 'Your log data here' })
})
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error('Error:', error));`}
      </pre>

      <h4>Python (Requests)</h4>
      <pre>
        {`import requests
import json

url = '${receiverEndpoint}'
payload = {'log': 'Your log data here'}
headers = {'Content-Type': 'application/json'}

response = requests.post(url, data=json.dumps(payload), headers=headers)
print(response.json())`}
      </pre>

      <h4>cURL</h4>
      <pre>
        {`curl -X POST ${receiverEndpoint} \\
-H "Content-Type: application/json" \\
-d '{"log": "Your log data here"}'`}
      </pre>

      <h4>Java (HttpClient)</h4>
      <pre>
        {`import java.net.URI;
import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import com.fasterxml.jackson.databind.ObjectMapper;

public class Example {
  public static void main(String[] args) throws Exception {
    HttpClient client = HttpClient.newHttpClient();
    ObjectMapper mapper = new ObjectMapper();
    String requestBody = mapper.writeValueAsString(Map.of("log", "Your log data here"));

    HttpRequest request = HttpRequest.newBuilder()
        .uri(URI.create("${receiverEndpoint}"))
        .header("Content-Type", "application/json")
        .POST(HttpRequest.BodyPublishers.ofString(requestBody))
        .build();

    HttpResponse<String> response = client.send(request, HttpResponse.BodyHandlers.ofString());
    System.out.println(response.body());
  }
}`}
      </pre>

      <h4>Node.js (Axios)</h4>
      <pre>
        {`const axios = require('axios');

axios.post('${receiverEndpoint}', {
  log: 'Your log data here'
}, {
  headers: {
    'Content-Type': 'application/json'
  }
})
.then(response => {
  console.log(response.data);
})
.catch(error => {
  console.error('Error:', error);
});`}
      </pre>
    </div>
  );
};

export default UsageInstructions;
