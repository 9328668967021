import React from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';

const parseTimestamp = (timestamp) => {
  // Try to parse ISO format
  let date = Date.parse(timestamp);
  if (!isNaN(date)) {
    return new Date(date);
  }

  // Fallback to parse ISO string
  try {
    return parseISO(timestamp);
  } catch (error) {
    console.error('Error parsing timestamp:', timestamp, error);
    return null; // Return null if parsing fails
  }
};

const LogList = ({ logs }) => {
  return (
    <div className="log-list">
      {logs.map((log, index) => {
        const parsedDate = parseTimestamp(log.timestamp);
        return (
          <div key={index} className="log-item">
            {/* <h3>Log {index + 1}</h3> */}
            <p><strong>Type:</strong> {log.TYPE}</p>
            <p>{parsedDate ? parsedDate.toLocaleString() : 'Invalid Date'} ( {parsedDate ? formatDistanceToNow(parsedDate, { addSuffix: true }) : 'Invalid Date'} ) </p>
            {/* <p><strong>Time Ago:</strong> {parsedDate ? formatDistanceToNow(parsedDate, { addSuffix: true }) : 'Invalid Date'}</p> */}
            <p><strong>Method:</strong> {log.method}</p>
            <details className="log-details">
              <summary><strong>Params</strong></summary>
              <pre>{JSON.stringify(log.params, null, 2)}</pre>
            </details>
            <details className="log-details">
              <summary><strong>Body</strong></summary>
              <pre>{JSON.stringify(log.body, null, 2)}</pre>
            </details>
            <details className="log-details">
              <summary><strong>Headers</strong></summary>
              <pre>{JSON.stringify(log.headers, null, 2)}</pre>
            </details>
          </div>
        );
      })}
    </div>
  );
};

export default LogList;
