// src/utils/localStorageUtils.js

export const getPrefetchedLogsFromStorage = () => {
    const storedLogs = localStorage.getItem('prefetchedLogs');
    return storedLogs ? JSON.parse(storedLogs) : {};
  };
  
  export const setPrefetchedLogsToStorage = (logs) => {
    localStorage.setItem('prefetchedLogs', JSON.stringify(logs));
  };
  
  export const getTotalPagesFromStorage = () => {
    const storedTotalPages = localStorage.getItem('totalPages');
    return storedTotalPages ? JSON.parse(storedTotalPages) : 0;
  };
  
  export const setTotalPagesToStorage = (totalPages) => {
    localStorage.setItem('totalPages', JSON.stringify(totalPages));
  };
  