import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogList from './components/LogList';
import Pagination from './components/Pagination';
import UsageInstructions from './components/UsageInstructions';
import './App.css';
import { 
  getPrefetchedLogsFromStorage, 
  setPrefetchedLogsToStorage, 
  getTotalPagesFromStorage, 
  setTotalPagesToStorage 
} from './utils/localStorageUtils';
import logo from './assets/logo.png'

const App = () => {
  const { isAuthenticated, loginWithRedirect, logout, getIdTokenClaims, user } = useAuth0();
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTime, setCurrentTime] = useState(null);
  const [totalPages, setTotalPages] = useState(getTotalPagesFromStorage());
  const [prefetchedLogs, setPrefetchedLogs] = useState(getPrefetchedLogsFromStorage());
  const [sub, setSub] = useState(null);
  const [prefetchingComplete, setPrefetchingComplete] = useState(false);
  const logSectionRef = useRef(null);
  const floatingButtonRef = useRef(null);

  const receiverEndpoint = sub
    ? `https://us-central1-logging-server-1c685.cloudfunctions.net/app/${sub}`
    : 'https://us-central1-logging-server-1c685.cloudfunctions.net/app/{your-id}';

  const fetchLogs = async (page, sub) => {
    try {
      const response = await fetch(`https://us-central1-logging-server-1c685.cloudfunctions.net/app/${sub}/cli/${page}`);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching logs:', error);
      return null;
    }
  };

  const prefetchAllLogs = useCallback(async (sub) => {
    let page = 1;
    let isPrefetchingComplete = false;
    const newPrefetchedLogs = {};
    while (!isPrefetchingComplete) {
      const data = await fetchLogs(page, sub);
      if (!data || data.length <= 1) {
        isPrefetchingComplete = true; // No more pages if data is empty or contains only the timestamp
      } else {
        newPrefetchedLogs[page] = data.slice(1);
        page++;
      }
    }
    setPrefetchedLogs(newPrefetchedLogs);
    setTotalPages(page - 1);
    setPrefetchingComplete(true);
    setPrefetchedLogsToStorage(newPrefetchedLogs);
    setTotalPagesToStorage(page - 1);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((claims) => {
        const sub = claims.sub; // Extract the sub from claims
        setSub(sub); // Set the sub state
        setLoading(true);
        fetchLogs(currentPage, sub).then((data) => {
          if (data) {
            setCurrentTime(data[0]?.timestamp); // Assuming the first element's timestamp is the current time
            setLogs(data.slice(1)); // Display the current page logs
          }
          setLoading(false);
        });
        prefetchAllLogs(sub);

        // Set up interval to fetch logs every 5 seconds
        const intervalId = setInterval(() => {
          fetchLogs(currentPage, sub).then((data) => {
            if (data) {
              setCurrentTime(data[0]?.timestamp); // Assuming the first element's timestamp is the current time
              setLogs(data.slice(1)); // Display the current page logs
            }
            setLoading(false);
          });
          prefetchAllLogs(sub);
        }, 5000); // Adjust the interval as needed

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
      });
    }
  }, [isAuthenticated, currentPage, getIdTokenClaims, prefetchAllLogs]);

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    if (prefetchedLogs[nextPage]) {
      setLogs(prefetchedLogs[nextPage]);
    } else {
      setLoading(true);
      fetchLogs(nextPage, sub).then((data) => {
        if (data) {
          setLogs(data.slice(1));
          const newPrefetchedLogs = { ...prefetchedLogs, [nextPage]: data.slice(1) };
          setPrefetchedLogs(newPrefetchedLogs);
          setPrefetchedLogsToStorage(newPrefetchedLogs);
        }
        setLoading(false);
      });
    }
    scrollToLogs()
  };

  const handlePreviousPage = () => {
    const prevPage = currentPage - 1;
    setCurrentPage(prevPage);
    setLogs(prefetchedLogs[prevPage]);
    scrollToLogs()
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (prefetchedLogs[pageNumber]) {
      setLogs(prefetchedLogs[pageNumber]);
    } else {
      setLoading(true);
      fetchLogs(pageNumber, sub).then((data) => {
        if (data) {
          setLogs(data.slice(1));
          const newPrefetchedLogs = { ...prefetchedLogs, [pageNumber]: data.slice(1) };
          setPrefetchedLogs(newPrefetchedLogs);
          setPrefetchedLogsToStorage(newPrefetchedLogs);
        }
        setLoading(false);
      });
    }
    scrollToLogs()
  };

  const scrollToLogs = () => {
    logSectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleScroll = () => {
    if (floatingButtonRef.current) {
      const usageInstructions = document.querySelector('.usage-instructions');
      const logViewingTable = logSectionRef.current;
      const button = floatingButtonRef.current;
      const usageInstructionsBottom = usageInstructions.getBoundingClientRect().bottom;
      const logViewingTableTop = logViewingTable.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (usageInstructionsBottom > windowHeight / 2) {
        button.style.top = `${windowHeight / 2}px`;
        button.style.position = 'fixed';
      } else {
        button.style.top = `${logViewingTableTop - windowHeight / 2}px`;
        button.style.position = 'absolute';
      }

      // Adjust the left position of the button to keep it close to the content
      const usageInstructionsLeft = usageInstructions.getBoundingClientRect().left;
      button.style.left = `${usageInstructionsLeft + usageInstructions.offsetWidth + 20}px`; // 20px margin from the content
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial position
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="App">
      <img src={logo} alt="Home Banner" className="home-banner" />
      <header className="App-header">
        <div className="user-info">
          {user && <span>Welcome, {user.given_name}.</span>}
          {isAuthenticated ? (
              <button onClick={() => logout({ returnTo: window.location.origin })}>Log out</button>
          ) : (
            <div className="login-button">
              <button onClick={() => loginWithRedirect()}>Log in to continue.</button>
            </div>
          )}
        </div>
      </header>
      {/* <h1 className='logs-title'>Logging Server</h1> */}
      <div className='content-body'>
        <div className='instructions'>
          <h1 className='logs-title'>Directions:</h1>
          <UsageInstructions receiverEndpoint={receiverEndpoint} scrollToLogs={scrollToLogs} />
        </div>
        {isAuthenticated && sub && (
        <div className='logs-section'>
            <>
              <h1 ref={logSectionRef} className='logs-title'>Log Entries:</h1>
              {loading && <p>Loading logs...</p>}
              {!loading && currentTime && <p>Current Time: {new Date(currentTime).toLocaleString()}</p>}
              {!loading && <LogList logs={logs} />}
              <Pagination 
                currentPage={currentPage} 
                totalPages={totalPages}
                onNextPage={handleNextPage} 
                onPreviousPage={handlePreviousPage} 
                onPageChange={handlePageChange}
              />
              {!prefetchingComplete && <p>Prefetching logs... <span className="loading-circle">🔄</span></p>}
            </>

        </div>          )}
      </div>
      {/* {isAuthenticated && (
        <div ref={floatingButtonRef} className="scroll-to-logs">
          <button onClick={scrollToLogs}>LOG VIEWER ⤵</button>
        </div>
      )} */}
    </div>
  );
};

export default App;
