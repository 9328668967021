import React, { useState } from 'react';

const Pagination = ({ currentPage, totalPages, onNextPage, onPreviousPage, onPageChange }) => {
  const [inputPage, setInputPage] = useState(currentPage);

  const handleInputChange = (event) => {
    setInputPage(event.target.value);
  };

  const handlePageChange = () => {
    const pageNumber = parseInt(inputPage, 10);
    if (pageNumber > 0 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    } else {
      alert(`Please enter a number between 1 and ${totalPages}`);
    }
  };

  return (
    <div className="pagination">
      <button onClick={onPreviousPage} disabled={currentPage === 1}>
        Previous
      </button>
      <span>Page {currentPage} of {totalPages}</span>
      <button onClick={onNextPage} disabled={currentPage === totalPages}>
        Next
      </button>
      <div className="page-input">
        <input
          type="number"
          value={inputPage}
          onChange={handleInputChange}
          min="1"
          max={totalPages}
        />
        <button onClick={handlePageChange}>Go</button>
      </div>
    </div>
  );
};

export default Pagination;
